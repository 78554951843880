import { single } from 'fuzzysort'
import isNil from 'lodash.isnil'
import type { ProductVariantDbPublicComposite } from '@centrito/api/nest/platform/database/domain'
import type { CategoryPublic } from '@centrito/api/nest/platform/database/domain'
import localStorageClient from '@centrito/app/utils/services/localStorage'

// Original: https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula

const GENDER_ORDER = ['Moda mujer', 'Moda Hombre', 'Niña', 'Niño', 'Bebé', 'Accesorios', 'Calzado']
const BEST_SELLING_ORDER = ['Bodys', 'Jeans', 'Chaquetas', 'Blusas']

// Takes a number like +571234567890 and returns 123-456-7890
export const formatPhoneNumberForForm = (unformattedPhoneNumber: string): string => {
  const unformattedWithoutCountryCode = unformattedPhoneNumber
    .replace('+57', '')
    .replace('+58', '')
    .replace(/-/g, '')
  const formattedPhoneNumber =
    unformattedWithoutCountryCode?.slice(0, 3) +
    '-' +
    unformattedWithoutCountryCode?.slice(3, 6) +
    '-' +
    unformattedWithoutCountryCode?.slice(6)
  return formattedPhoneNumber
}

export const compareCategories = (a: CategoryPublic, b: CategoryPublic): number => {
  const aProcessed = BEST_SELLING_ORDER.indexOf(a.names[1].split(' ')[0])
  const bProcessed = BEST_SELLING_ORDER.indexOf(b.names[1].split(' ')[0])
  // 1. compare by gender
  if (GENDER_ORDER.indexOf(a.names[0]) < GENDER_ORDER.indexOf(b.names[0])) {
    return -1
  }
  if (GENDER_ORDER.indexOf(a.names[0]) > GENDER_ORDER.indexOf(b.names[0])) {
    return 1
  }
  // 2. One of the categories doesnt exist on bestsellingOrder
  if (aProcessed < 0 && bProcessed < 0) {
    return 0
  }
  if (aProcessed < 0) {
    return 1
  }
  if (bProcessed < 0) {
    return -1
  }
  // 3. compare by bestSelling
  if (aProcessed < bProcessed) {
    return -1
  }
  if (aProcessed > bProcessed) {
    return 1
  }
  return 0
}

export const isEmailValid = (email: string | undefined): boolean => {
  if (!email) return false
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
    ? true
    : false
}

export const getSearchVariant = (
  searchTerm: string,
  listOfVariants: ProductVariantDbPublicComposite[],
): number => {
  // 1. preprocess
  const preprocess = listOfVariants.map((productVariant, variantIndex) => {
    const variantColor =
      productVariant.variant.colorData.primary?.map((color) => color.key).join(' ') || ''
    const splitted: string[] = variantColor.split(/[ -]/gm)
    return splitted.map((variantColor) => ({
      variantIndex,
      variantColor,
    }))
  })

  // 2. flatten
  const flatPreprocess = preprocess.flat()

  // 3. get scores
  const fuzzyScores = flatPreprocess.map(({ variantColor, variantIndex }) => {
    const result = single(searchTerm, variantColor)
    return { variantIndex, score: result ? result.score : -Infinity }
  })

  // get max score
  const maxScore = fuzzyScores.reduce(
    (prev, curr) => {
      return curr.score > prev.score ? curr : prev
    },
    { variantIndex: 0, score: -Infinity },
  )

  return maxScore.variantIndex
}

export const queryParamsToQueryString = (queryParams: object, prefix = '?'): string => {
  const queryStrings = Object.entries(queryParams)
    .map(([k, v]) => `${k}=${v}`)
    .join('&')
  return queryStrings.length > 0 ? `${prefix}${queryStrings}` : ''
}

export const DOWNLOAD_DIALOG_KEY = 'download_dialog'
export const DOWNLOAD_HEADER_KEY = 'download_header'

export const setLocalStorageExpiration = async (
  itemKey: string,
  duration: number,
): Promise<void> => {
  const now = new Date().getTime()
  const expiresAt = now + duration
  await localStorageClient.setItem(`${itemKey}_expiration`, JSON.stringify({ expiresAt }))
}

export const hasLocalStorageItemExpired = async (itemKey: string): Promise<boolean> => {
  const expirationRecord = await localStorageClient.getItem(`${itemKey}_expiration`)

  if (isNil(expirationRecord)) {
    return true
  }

  const { expiresAt } = JSON.parse(expirationRecord)
  const now = new Date().getTime()

  if (now > expiresAt) {
    return true
  }

  return false
}

export const isIosBrowser = (): boolean => {
  return !isNil(navigator) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
}
